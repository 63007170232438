<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="WorkAtMalabs"></company-submenu>

    <div class="submenu-content">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <div v-for="(hero, index) in workatmalabs_banner" :key="hero">
            <div v-if="index == 0">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                :data-bs-slide-to="index"
                class="active"
                aria-current="true"
              ></button>
            </div>
            <div v-else>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                :data-bs-slide-to="index"
              ></button>
            </div>
          </div>
        </div>
        <div class="carousel-inner">
          <div
            class="carousel-item"
            v-for="(hero, index) in workatmalabs_banner"
            :class="{ active: index == 0 }"
            :key="hero"
          >
            <img :src="hero[0]" class="d-block w-100" />
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-9">
          <div v-html="workatmalabs_para" class="paragraph"></div>
        </div>
        <div class="col-3">
          <br />
          <p style="font-weight:bold;">Learn More</p>
          <hr
            style="border:2px solid #ccc;margin:-4px 0 10px 0;color: #ffffff;"
          />
          <p style="color:blue;font-size:90%;">
            <router-link :to="{ name: 'JobOpenings' }"
              >Job Openings</router-link
            >
          </p>
          <p style="color:blue;font-size:90%;">
            <a
              href="https://www.eeoc.gov/eeo-policy-statement"
              target="_blank"
              >Equal Opportunity Employer</a
            >
          </p>
          <p style="color:blue;font-size:90%;">
            <a href="https://www.e-verify.gov/" target="_blank">E-Verify</a>
          </p>
          <p style="font-weight:bold; padding-top:20px">Stay Connected</p>
          <hr
            style="border:2px solid #ccc;margin:-4px 0 10px 0;color: #ffffff;"
          />
          <a href="https://www.linkedin.com/company/malabs" target="_blank"
            ><img src="@/assets/images/linkedin_icon.jpg" :height="50"
          /></a>
          &nbsp
          <a
            href="https://www.glassdoor.com/Overview/Working-at-MA-Laboratories-EI_IE3274.11,26.htm"
            target="_blank"
            ><img src="@/assets/images/glassdoor_icon.jpg" :height="50"
          /></a>
          <br /><br />
          <img
            src="@/assets/images/Forbes_2020_Award.png"
            :height="150"
            :width="215"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref } from "vue";

export default {
  name: "WorkAtMalabs",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const workatmalabs_banner = ref({});
    const workatmalabs_para = ref("");
    const error = ref(null);

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Careers", link: "Careers"})
    breadcrumb_links.value.push({text: "Work at Ma Labs", link: "WorkAtMalabs"})

    let workatmalabs_url = "content/pages/46";
    getAPI
      .get(workatmalabs_url)
      .then((response) => {
        if (response.status === 200) {
          let blocks = response.data.body;
          for (let index in blocks) {
            if (blocks[index].type == "image") {
              // console.log(blocks[index].value.large.src)
              // workatmalabs_banner.value.push(blocks[index].value.large.src);
              workatmalabs_banner.value[index] = [
                blocks[index].value.large.src,
              ];
            }
            if (blocks[index].type == "paragraph") {
              workatmalabs_para.value = blocks[index].value;
              // console.log(blocks[index].value)
            }
          }
        }
      })
      .catch((err) => {
        error.value = "No page Found";
      });
    return { workatmalabs_banner, workatmalabs_para, error, breadcrumb_links };
  },
};
</script>

<style scoped>
b{
  color: blue;
}
a{
  color: #555;
}
</style>
